import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
const About = () => {
    return(
        <Layout>
            <Head title='About' descriptionContent='SilverGoldPill is all about providing free unbiased information on everything related to silver and gold metals.' />
            <h2>About</h2>
            <ul>
                <li><Link to={'./privacy-and-copyright'}>Privacy Policy & Copyright Information</Link></li>
            </ul>

            <p>Have you taken the pill? The silver or gold pill? Once you start collecting or stacking precious metals there is no going back, and having a reliable source in the precious metal community is important.</p>
            
            <p><b>SilverGoldPill.com</b> strives to be an <b>unbiased</b> source to inform amateur and seasoned silver and gold collectors about upcoming coin releases, new silver and gold series from various mints, and any informative issues and trends in the silver and gold community.</p>
            
            <p>Whether you are interested in silver or gold, coins or rounds, hand poured bars or 100 oz. bars, <b>SilverGoldPill.com</b> has informative posts on market trends for you.</p>

            <p><b>SilverGoldPill.com</b> is not sponsored or endorsed by any precious metal retailers or companies, so you can expect to receive honest commentary from an authority with over a decade in the precious metal community, but rememeber precious metals are speculative and our opinons are just that.</p>

            <p>We hope <b>SilverGoldPill.com</b> can aid you and answer any questions you might have about precious metals and silver and gold coins. If you have any blog article suggestions or If you would like to get in contact with me, try <Link to={'./contact'}>here</Link> to get in touch, as I would really like to hear from you.</p>

            <br /><br />
        </Layout>
    )
}
export default About